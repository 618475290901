<template>
  <div v-if="arrayInvoices">
    <b-badge
      v-if="arrayInvoices.length > 0"
      variant="light-danger"
    >
      Last 60 days
    </b-badge>
    <div
      v-for="(inv, index) in arrayInvoices.slice(0, 5)"
      :id="`custom_invoice_id_${index}`"
      :key="inv.id"
      class="position-relative invoices-wrapper"
      :class="index !== 0 && 'mt-1'"
    >
      <div class="invoice-item">
        <div
          class="item-close"
          @click="hideInvoice(index, inv)"
        >
          <feather-icon
            icon="XIcon"
            size="16"
            class="align-text-top"
          />
        </div>
        <p class="m-0">
          <strong class="mr-1 text-dark"><feather-icon
            icon="CalendarIcon"
            size="16"
            class="align-text-top"
          /> {{ formatDate(inv.issueDate) }}</strong>
          <span>Invoice# {{ inv.invoiceNo }}</span>
        </p>
        <h4 class="d-flex mb-1 mt-1 align-items-center">
          <router-link
            v-b-tooltip.hover.left.v-success
            :to="`/user/details/${inv.uid}`"
            title="Go to User"
            class="mr-1"
          >
            <feather-icon
              icon="UserIcon"
              size="27"
              class="text-success"
            />
          </router-link>
          <a
            v-b-tooltip.hover.top.v-primary
            :href="`/invoice/custom/${inv.id}`"
            title="Go to Invoice"
            class="text-primary"
          >
            <span>{{ inv.packageName ? inv.packageName : 'Custom Product' }}</span>
          </a>
        </h4>
        <p class="m-0">
          <span
            class="font-weight-bold"
            style="margin-right: 2px;"
          >Invoice value:</span> {{ `${inv.currency.toUpperCase()} ${parseFloat(inv.total).toFixed(2)} (inc.VAT ${inv.vatPercent}%)` }}
        </p>
        <p
          v-if="inv.periodStart"
          class="m-0"
        >
          <span
            class="font-weight-bold"
            style="margin-right: 2px;"
          >Period:</span>  {{ `${formatDate(inv.periodStart)} - ${formatDate(inv.periodEnd)}` }}
        </p>
        <p class="m-0">
          <span
            class="font-weight-bold"
            style="margin-right: 2px;"
          >Client:</span> {{ inv.to }}
        </p>
      </div>
    </div>

    <p class="mt-2 text-right font-weight-bold">
      <router-link to="/billing/invoices-register">
        See all {{ customInvoiceCount }}
      </router-link>
    </p>

  </div>
</template>

<script>
import {
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      customInvoiceCount: 0,
      arrayInvoices: [],
    }
  },
  computed: {
    adminData() {
      return this.$store.state.home.adminData
    },
  },
  mounted() {
    this.getCustomInvoiceCount()
    this.filteredInvoices()
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    getCustomInvoiceCount() {
      this.customInvoiceCount = Number(this.adminData.customInvoices.length)
    },
    filteredInvoices() {
      this.arrayInvoices = this.adminData.customInvoices.filter(e => {
        const now = moment(new Date())
        const date = moment(e.created_at)
        const diff = now.diff(date, 'days')
        return diff <= 60 && e.visible === 1 && e.invoice_status === 'overdue'
      })
    },
    hideInvoice(index, { id, credit_note }) {
      this.confirmationAlert('Are you sure you want to hide this custom invoice ?').then(res => {
        if (res.isConfirmed) {
          const el = document.getElementById(`custom_invoice_id_${index}`)
          el.classList.add('fadeout')
          this.$store.dispatch('home/hideInvoice', { id, credit_note, type: 'custom' })
          setTimeout(() => {
            this.arrayInvoices.splice(index, 1)
            el.remove()
          }, 600)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.fadeout {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.invoices-wrapper {
  border: 1px groove #c7c7c7;
  border-left: 4px solid #ff9f43;
  padding: 15px 26px;
  border-radius: 5px;
  .invoice-item {
    .item-close {
      background: #ea5455;
      border-left: 1px solid #c7c7c7;
      border-bottom: 1px solid #c7c7c7;
      border-radius: 0px 5px 0px 5px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
      transition: .5s;

      &:hover {
        background: #b30d46;
      }
    }
  }
}
</style>
