<template>
  <div
    v-if="adminData"
    class="home-wrapper"
  >
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <router-link :to="'/tickets/open-tickets'">
          <statistic-card-horizontal
            icon="ToolIcon"
            color="primary"
            :statistic="adminData.open_last"
            statistic-title="Open Tickets"
          />
        </router-link>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <router-link :to="'users/leads'">
          <statistic-card-horizontal
            icon="UsersIcon"
            color="info"
            :statistic="adminData.leads"
            statistic-title="Leads"
          />
        </router-link>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <router-link :to="'users/registered'">
          <statistic-card-horizontal
            icon="UserCheckIcon"
            color="primary"
            :statistic="adminData.registered"
            statistic-title="Registered"
          />
        </router-link>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <router-link :to="'users/subscribed'">
          <statistic-card-horizontal
            icon="UserPlusIcon"
            color="success"
            :statistic="adminData.active"
            statistic-title="Subscribed"
          />
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <router-link :to="'users/pending-users'">
          <statistic-card-horizontal
            icon="ClockIcon"
            color="warning"
            :statistic="adminData.pending"
            statistic-title="Pending Users"
          />
        </router-link>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <router-link :to="'users/trial-users'">
          <statistic-card-horizontal
            icon="UserIcon"
            color="warning"
            :statistic="adminData.trial"
            statistic-title="Trial Users"
          />
        </router-link>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <router-link :to="'users/expired'">
          <statistic-card-horizontal
            icon="UserMinusIcon"
            color="danger"
            :statistic="adminData.expired"
            statistic-title="Expired/Suspended Users"
          />
        </router-link>
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <router-link :to="'users/canceled'">
          <statistic-card-horizontal
            icon="UserXIcon"
            color="secondary"
            :statistic="adminData.canceled"
            statistic-title="Cancelled Users"
          />
        </router-link>
      </b-col>
    </b-row>
    <b-row class="d-flex">
      <b-col
        sm="3"
        style="margin-bottom: 4px"
      >
        <b-form-select
          v-model="monthsOptions"
          @change="monthChange()"
        >
          <b-form-select-option value="thisMonth">
            This Month ({{ thisMonth }})
          </b-form-select-option>
          <b-form-select-option value="lastMonth">
            Last Month ({{ lastMonth }})
          </b-form-select-option>
          <b-form-select-option value="3">
            Last 90 Days (3 Months)
          </b-form-select-option>
          <b-form-select-option value="6">
            Last 180 Days (6 Months)
          </b-form-select-option>
          <b-form-select-option value="12">
            Last 360 Days (12 Months)
          </b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-row class="d-flex justify-content-center">
        <b-col
          sm="6"
        >
          <statistic-card-with-line-chart
            :icon="parseFloat(billedSeries[0].data[billedSeries[0].data.length -2]) > parseFloat(billedSeries[0].data[billedSeries[0].data.length -1]) ? 'TrendingDownIcon' : 'TrendingUpIcon'"
            color="success"
            :statistic="`${Math.trunc(adminData.billed_last)} €`"
            :statistic-title="`INVOICED ${months(monthsOptions)}`"
            :chart-data="billedSeries"
            :custom_recurrent="Math.trunc(adminData.billed_cr)"
            :custom_intervention="Math.trunc(adminData.billed_ci)"
            :average-title="`Average : ${Math.trunc(adminData.billed_avg)}€/Month`"
            :url="`/billing/invoices-register?last30days=${days(monthsOptions)}`"
            :time-frame="monthsOptions"
            :is-paid1="false"
          />
        </b-col>
        <b-col
          sm="6"
        >
          <statistic-card-with-line-chart
            :icon="parseFloat(paidSeries[0].data[paidSeries[0].data.length -2]) > parseFloat(paidSeries[0].data[paidSeries[0].data.length -1]) ? 'TrendingDownIcon' : 'TrendingUpIcon'"
            color="info"
            :statistic="`${Math.trunc(adminData.paid_last)} €`"
            :statistic-title="`PAID ${months(monthsOptions)}`"
            :chart-data="paidSeries"
            :custom_recurrent="Math.trunc(adminData.paid_cr)"
            :custom_intervention="Math.trunc(adminData.paid_ci)"
            :average-title="`Average : ${Math.trunc(adminData.paid_avg)}€/Month`"
            :url="`/billing/invoices-register?last30days=${days(monthsOptions)}`"
            :time-frame="monthsOptions"
            :is-paid1="true"
          />
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-col
          sm="6"
        >
          <statistic-card-with-line-chart
            :icon="parseFloat(unpaidSeries[0].data[unpaidSeries[0].data.length -2]) > parseFloat(unpaidSeries[0].data[unpaidSeries[0].data.length -1]) ? 'TrendingDownIcon' : 'TrendingUpIcon'"
            color="danger"
            :statistic="`${Math.trunc(adminData.unpaid_last)} €`"
            :statistic-title="`UNPAID ${months(monthsOptions)}`"
            :chart-data="unpaidSeries"
            :average-title="`Average : ${Math.trunc(adminData.unpaid_avg)}€/Month`"
            :url="`/billing/pending-forma-invoices?last30days=${days(monthsOptions)}`"
            :time-frame="monthsOptions"
          />
        </b-col>
        <b-col
          sm="6"
        >
          <statistic-card-with-line-chart
            :icon="parseFloat(overdueSeries[0].data[overdueSeries[0].data.length -2]) > parseFloat(overdueSeries[0].data[overdueSeries[0].data.length -1]) ? 'TrendingDownIcon' : 'TrendingUpIcon'"
            color="warning"
            :statistic="`${Math.trunc(adminData.overdue_last)} €`"
            :statistic-title="`OVERDUE ${months(monthsOptions)}`"
            :chart-data="overdueSeries"
            :average-title="`Average : ${Math.trunc(adminData.overdue_avg)}€/Month`"
            :url="`/billing/overdue-forma-invoices?last30days=${days(monthsOptions)}`"
            :time-frame="monthsOptions"
          />
        </b-col>
      </b-row>
    </b-overlay>
    <b-row>
      <b-col md="6">
        <b-card-actions
          title="OVERDUE PRO FORMA INVOICES"
          action-collapse
        >
          <pro-forma-invoices />
        </b-card-actions>
      </b-col>
      <b-col md="6">
        <b-card-actions
          title="OVERDUE CUSTOM INVOICES"
          action-collapse
        >
          <custom-invoices />
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormSelectOption, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import moment from 'moment'
import ProFormaInvoices from './ProFormaInvoices.vue'
import CustomInvoices from './CustomInvoices.vue'

export default {
  components: {
    BOverlay,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BCardActions,
    StatisticCardHorizontal,
    StatisticCardWithLineChart,
    'pro-forma-invoices': ProFormaInvoices,
    'custom-invoices': CustomInvoices,
  },
  data() {
    return {
      monthsOptions: 'lastMonth',
      isLoading: false,
      lastMonth: '',
      thisMonth: '',
      series: [
        {
          data: [280, 200, 220, 180, 270],
        },
      ],
    }
  },
  computed: {
    adminData() {
      return this.$store.state.home.adminData
    },
    overdueSeries() {
      return [
        {
          data: this.$store.state.home.adminData.overdue_series,
        },
      ]
    },
    billedSeries() {
      return [
        {
          data: this.$store.state.home.adminData.billed_series,
        },
      ]
    },
    paidSeries() {
      return [
        {
          data: this.$store.state.home.adminData.paid_series,
        },
      ]
    },
    unpaidSeries() {
      return [
        {
          data: this.$store.state.home.adminData.unpaid_series,
        },
      ]
    },
  },
  created() {
    this.fetchAdmin()
    this.lastMonth = moment().subtract(1, 'month').format('MMMM')
    this.thisMonth = moment().format('MMMM')
  },
  methods: {
    months(months) {
      let label = ''
      if (months === 'lastMonth') {
        label = `(In ${this.lastMonth})`
      } else if (months === 'thisMonth') {
        label = `(In ${this.thisMonth})`
      } else {
        label = `(LAST ${months * 30} Days)`
      }
      return label
    },
    days(months) {
      let label = 0
      if (months === 'lastMonth') {
        label = this.monthsOptions
      } else if (months === 'thisMonth') {
        label = this.monthsOptions
      } else {
        label = months * 30
      }
      return label
    },
    fetchAdmin() {
      this.$store.dispatch('home/fetchAdmin', this.monthsOptions)
    },
    monthChange() {
      this.isLoading = true
      this.$store.dispatch('home/fetchAdmin', this.monthsOptions).then(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style>
.home-wrapper div.col-sm-6.col-lg-3 a{
    color: #6e6b7b;
}
.dark-layout .invoices-wrapper{
  border: 1px groove #363949
}
.dark-layout .invoices-wrapper .invoice-item .item-close{
  border: 1px groove #363949
}
</style>
